@tailwind base;
@tailwind components;
@tailwind utilities;

$pb-pink: rgb(228, 99, 192);
$pb-background: #061634;

body {
  background: linear-gradient(180deg, #001247, #5d068d);
  overflow-x: hidden;
}

section {
  min-width: 100vw;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0;
}
:root {
  --primary: turquoise;
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
}

.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hero p {
  color: transparent;
}

.hero-heading {
 color: $pb-pink
}

.hero-secondary {
  --mask: radial-gradient(
    circle at var(--x, 50%) var(--y, 50%),
    black var(--maskSize1, 0%),
    transparent 0,
    transparent var(--maskSize2, 0%),
    black var(--maskSize2, 0%),
    black var(--maskSize3, 0%),
    transparent 0
  );
  position: absolute;
  width: 100%;
  height: 100%;
  mask-image: var(--mask);
  -webkit-mask-image: var(--mask);
  /* clip-path: circle(15% at var(--x, 70%) var(--y, 50%)); */
}
.hero-secondary .hero-heading {
  background: radial-gradient(circle at center, #090e17 0.05rem, transparent 0);
  background-size: 0.15rem 0.15rem;
  filter: none;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: unset;
}
.hero-secondary p {
  color: rgb(255, 255, 255);
}
